/* General styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  color: #333;
}

.container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Flexbox layout for the header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.header img {
  max-width: 40%;
  border-radius: 8px;
}

.header .text {
  max-width: 55%;
}

.header .text h1 {
  font-size: 2.5em;
  color: #007BFF;
  margin-bottom: 10px;
}

.header .text p {
  font-size: 1.2em;
  color: #555;
}

/* Typography */
h2, h3, h4, h5, h6 {
  color: #333;
  margin: 20px 0;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 2em;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 5px;
}

h3 {
  font-size: 1.5em;
  margin-top: 20px;
  color: #007BFF;
}

p {
  line-height: 1.6;
  margin: 15px 0;
}

/* List styling */
ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

ul li:last-child {
  border-bottom: none;
}

/* Section styling */
.section {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Animation styling */
.wow {
  visibility: hidden;
}

.animate__animated {
  visibility: visible !important;
}

/* Competency group styling */
.competency-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.competency {
  width: 30%;
  background-color: rgba(240, 240, 240, 0.8);
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.competency img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
}

.competency h3 {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #007BFF;
}

.competency ul {
  padding: 0;
  list-style-type: disc;
  padding-left: 20px;
}

.competency ul li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.competency ul li:last-child {
  border-bottom: none;
}

/* Introductory text styling */
.competencies-intro {
  margin-bottom: 20px;
  font-style: italic;
}

/* Experience section styling */
.experience-section {
  display: flex;
  justify-content: space-between;
}

.experience-column {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  margin-bottom: 20px;
}

.experience-column img {
  max-width: 80%;
  margin: 10px 0;
  align-self: center;
}

/* Contact details styling */
.contact-details {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  border-left: 5px solid #007BFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-details p {
  margin: 10px 0;
  font-weight: bold;
}

/* Footer styling */
.footer {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footer a {
  color: #007BFF;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header img {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .header .text {
    max-width: 100%;
  }

  .competency-row {
    flex-direction: column;
    align-items: center;
  }

  .competency {
    width: 100%;
    margin-bottom: 20px;
  }

  .experience-section {
    flex-direction: column;
  }

  .experience-column {
    width: 100%;
    text-align: center;
  }

  .experience-column img {
    max-width: 80%;
    margin: 20px 0;
  }

  h2 {
    font-size: 1.5em;
  }
}
